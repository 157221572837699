/********* rac color variables  ********/
/********* rac font variables  ********/
/********* rac BreakPoint variables  ********/
.theme-switching-animation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  display: none;
}
.theme-switching-animation img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.site-take-over-switch {
  border-radius: 5px;
  background: #E26C14;
  box-shadow: 4px 4px 5px 4px rgba(0, 0, 0, 0.13);
  flex: 1;
  cursor: pointer;
}
.site-take-over-switch.bunnyTheme {
  background: #49148A;
}
.site-take-over-switch.bunnyTheme .theme-icon {
  filter: brightness(0) saturate(100%) invert(39%) sepia(56%) saturate(2597%) hue-rotate(3deg) brightness(99%) contrast(105%);
}
.site-take-over-switch.disabled {
  cursor: not-allowed;
}
.site-take-over-switch .theme-switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 18px;
}
.site-take-over-switch .theme-switch:has(input:disabled) {
  opacity: 0.5;
}
.site-take-over-switch .theme-switch:has(input:disabled) .theme-switch-slider {
  cursor: not-allowed;
}
.site-take-over-switch .theme-switch .theme-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  -webkit-transition: .4s;
  transition: .4s;
}
.site-take-over-switch .theme-switch .theme-switch-slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 3px;
  background-color: #9b9b9b;
  -webkit-transition: .4s;
  transition: .4s;
}
.site-take-over-switch .theme-switch .theme-switch-slider.round {
  border-radius: 34px;
}
.site-take-over-switch .theme-switch .theme-switch-slider.round:before {
  border-radius: 50%;
}
.site-take-over-switch .theme-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.site-take-over-switch .theme-switch input:focus + .theme-switch-slider {
  box-shadow: 0 0 1px #2196F3;
}
.site-take-over-switch .theme-switch input:checked + .theme-switch-slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
.theme-switching-animation.bunnyTheme {
  background: #FFE2EB !important;
}
.theme-switching-animation.bunnyTheme img {
  object-fit: contain !important;
}
